import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { automation } from "src/app/auth/model/automation";
import { environment } from "src/environments/environment";
import { TestDataResponse } from "../../auth/model/testResponse";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class TestDataService {
  constructor(private http: HttpClient, private router: Router) {}

  addTestData(testData, PatientID) {
    console.log(testData);
    return this.http.post<{
      message: string;
      error?: boolean;
      TestResults;
    }>(`${environment.baseUrl}/test/add-testResult`, testData);
  }

  getTestDetails(id) {
    return this.http.get<{
      error: boolean;
      message: string;
      testResults: any; //TestDataResponse[]
    }>(`${environment.baseUrl}/test/get-testResult/${id}`);
  }

  sendEmail(PatientID) {
    return this.http.get<{
      error: boolean;
      message: string;
    }>(`${environment.baseUrl}/mail/${PatientID}`);
  }

  sendEmailToOther(form) {
    return this.http.post<{
      error: boolean;
      message: string;
    }>(`${environment.baseUrl}/mail-other/`, form);
  }

  lipidAutomationTest() {
    return this.http.get<{
      automation: automation;
    }>(`${environment.lipidProfileUrl}`);
  }

  bodyCompositionTest() {
    return this.http.get<{
      automation: automation;
    }>(`${environment.bodyCompositionUrl}`);
  }

  OXYAutomationTest() {
    return this.http.get<{
      automation: automation;
    }>(`${environment.OXYUrl}`);
  }

  SugarAutomationTest() {
    return this.http.get<{
      automation: automation;
    }>(`${environment.SugarUrl}`);
  }

  BloodPressureAutomationTest() {
    // const randomString = Math.floor(Math.random() * 9000000000) + 1000000000;
    const min = 100000000000; // Smallest 12-digit number
    const max = 999999999999; // Largest 12-digit number
    let randomNumber= Math.floor(Math.random() * (max - min + 1)) + min;

    return this.http.get<{
      automation: automation;
    }>(`${environment.PerkantTechBaseUrl}/scan/start/${environment.PerkantMachineId}/${randomNumber}`);
  }
  
  getCalculatedData(queryParams: any): Observable<any> {
   // Create an HttpParams object to build the query parameters
   let params = new HttpParams();

   // Iterate through the object and add each key-value pair as a parameter
   for (const key in queryParams) {
     if (queryParams.hasOwnProperty(key)) {
       params = params.append(key, queryParams[key]);
     }
   }
   // Make the GET request with the query parameters
   return this.http.get<any>(`${environment.baseUrl}/testPrice/get-total-price`, { params });
  }




  getAllVitalsData(Scan_ID: string,User_ID:String) {
  

    // Use the params in the GET request
    return this.http.get<any>(`${environment.PerkantTechBaseUrl}/userdata/getinfo/${Scan_ID}`);
  }
  DengueNs1AutomationTest() {
    return this.http.get(`${environment.DengueNs1Url}`);
  }

  Hba1cAutomationTest() {
    let requestURL = environment.Hba1cUrl;
    const MachineID = JSON.parse(
      localStorage.getItem("technicianDetails")
    ).MachineID;
    if (MachineID) {
      requestURL += `?MachineID=${MachineID}`;
    }
    return this.http.get(requestURL);
  }

  AutomationTest(payload):any {
    let requestURL = environment.Hba1cUrl;
    const MachineID = JSON.parse(
      localStorage.getItem("technicianDetails")
    ).MachineID;
    if (MachineID) {
      requestURL += `/${MachineID}`;
    }
    return this.http.post<any>(requestURL, payload);
  
  }

  // UrineAutomationTest(){
  //   let requestURL = environment.Hba1cUrl;
  //   const MachineID = JSON.parse(
  //     localStorage.getItem("technicianDetails")
  //   ).MachineID;
  //   if (MachineID) {
  //     requestURL += `/${MachineID}`;
  //   }
  //   return this.http.post<any>(requestURL);
  //   console.log("HEllo")
  // }

  UrineAutomationTest(){
    let requestURL = environment.UrineTestUrl;
    const MachineID = JSON.parse(
      localStorage.getItem("technicianDetails")
    ).MachineID;
    if (MachineID) {
      requestURL += `?MachineID=${MachineID}`;
    }
    return this.http.get<any>(requestURL);
  }

  CBCAutomationTest() {
    let requestURL = environment.CBCUrl;
    const MachineID = JSON.parse(
      localStorage.getItem("technicianDetails")
    ).MachineID;
    if (MachineID) {
      requestURL += `?MachineID=${MachineID}`;
    }
    return this.http.get<any>(requestURL);
  }
}

//  CbcAutomationLatestTest(){
//   let requestURL = environment.CBCUrl;
//   const MachineID = JSON.parse(
//     localStorage.getItem("technicianDetails")
//   ).MachineID;
//   if (MachineID) {
//     requestURL += `?MachineID=${MachineID}`;
//   }
//   return this.http.get(requestURL);
//  }