import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PatientResponse } from "src/app/auth/model/patientResponse";
import { environment } from "src/environments/environment";
@Injectable({
  providedIn: "root",
})
export class PatientServiceService {
  constructor(private http: HttpClient) {}

  getPatientLatestId() {
    return this.http.get<{
      error: boolean;
      lastPatient;
    }>(`${environment.baseUrl}/patient/get-patientId`);
  }

  getPatient(patientDetail) {
    return this.http.get<{
      error: boolean;
      message: string;
      patients: PatientResponse[];
    }>(`${environment.baseUrl}/patient/search-patient/${patientDetail}`);
  }

  getAllPatient() {
    return this.http.get<{
      error: boolean;
      message: string;
      patients: PatientResponse[];
    }>(`${environment.baseUrl}/patient/get-patient`);
  }

  getPatientDetail(patientId) {
    return this.http.get<{
      error: boolean;
      message: string;
      patients: PatientResponse[];
    }>(`${environment.baseUrl}/patient/get-patient/${patientId}`);
  }

  getPatientTestDetails(patientId) {
    return this.http.get<{
      error: boolean;
      message: string;
      Test: any;
    }>(`${environment.baseUrl}/patient/get-patientTest/${patientId}`);
  }

  // getAllPatientDetailsService() {

  // }
  // getSinglePatientDetailService(patientId) {
  //   return this.http.get < {
  //     error: boolean,
  //     message: string,
  //     patients: PatientResponse[]
  //   } > (`${environment.baseUrl}/patient/get-SinglePatient/${patientId}`)
  // }

  addPatient(patientDetails) {
    return this.http.post<{
      message: string;
      error?: boolean;
    }>(`${environment.baseUrl}/patient/add-patient`, patientDetails);
  }

  uploadAAdhaar(imagefile) {
    return this.http.post<{
      key: string;
      error?: boolean;
    }>(`${environment.baseUrl}/patient/uplode-adhar-img`, imagefile);
  }

  registerPatient(patientDetails) {
    return this.http.post<any>(
      `${environment.baseUrl}/patient/register-patient`,
      patientDetails
    );
  }


  updatePatientdetails(patient: PatientResponse) {
    return this.http.put<{
      error: boolean;
      message: string;
      _id: string;
      response: PatientResponse;
    }>(`${environment.baseUrl}/patient/edit-patient/${patient._id}`, patient);
  }

  updatePatientdetailsDashboard(patient: any, patientId: string) {
    return this.http.patch<{
      error: boolean;
      message: string;
      response: any;
    }>(`${environment.baseUrl}/patient/update-patient/${patientId}`, patient);
  }

  deletePatient(id: string) {
    return this.http.delete<{
      error: boolean;
      message: string;
    }>(`${environment.baseUrl}/patient/delete-patient/${id}`);
  }

  addReport(report: string, id: string) {
    const postData = new FormData();
    postData.append("pdf", report);
    return this.http.post<{
      error: boolean;
      message: string;
    }>(`${environment.baseUrl}/report/add-report/${id}`, postData);
  }

  getPatientTestDatas(patientId) {
    return this.http.get<{
      error: boolean;
      message: string;
      Test: [];
    }>(`${environment.baseUrl}/patient/get-patientTest/${patientId}`);
  }
}
