import { Pipe, PipeTransform } from "@angular/core";
import { PatientResponse } from "src/app/auth/model/patientResponse";

@Pipe({
  name: "patientSearch",
  pure: false,
})
export class SearchPatientsPipeInHome implements PipeTransform {
  transform(patient: PatientResponse[], searchString: string) {
    if (searchString) {
      return patient.filter((patient) => {
        let patientIdSearch = patient.PatientID.toLowerCase().includes(
          searchString.toLowerCase().replace(/\s/g, "")
        );
        let patientIdName =
          patient.Name.search(new RegExp(searchString, "i")) > -1;
        let patientNumber = patient.contactNumber
          .toString()
          .includes(searchString.toString());
        if (patientIdSearch || patientIdName || patientNumber) {
          return patientIdSearch || patientIdName || patientNumber;
        }
      });
      // } else {
      //   return patient;
    }
  }
}
