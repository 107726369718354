import { HttpClient } from "@angular/common/http";
import { Component, OnInit, NgZone } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";

function _window(): any {
  return window;
}

@Component({
  selector: "app-payment-component",
  templateUrl: "./payment-component.component.html",
  styleUrls: ["./payment-component.component.css"],
})
export class PaymentComponentComponent implements OnInit {
  get nativeWindow(): any {
    return _window();
  }

  BodyCompositionChecked: boolean;
  BloodPressureChecked: boolean;
  TempChecked: boolean;
  OXYChecked: boolean;
  CkmbChecked: boolean;
  TnlChecked: boolean;
  DimerChecked: boolean;
  proBnpChecked: boolean;
  hsCRPChecked: boolean;
  LipidProfileChecked: boolean;
  HcgChecked: boolean;
  LhChecked: boolean;
  T4Checked: boolean;
  fT4Checked: boolean;
  TshWbChecked: boolean;
  TshChecked: boolean;
  CrpChecked: boolean;
  PctChecked: boolean;
  Hba1cChecked: boolean;
  BloodGlucoseChecked: boolean;
  AlbuminChecked: boolean;
  invasiveChecked: boolean;
  RsvAgChecked: boolean;
  LegionellaChecked: boolean;
  SpneumoniaChecked: boolean;
  CovidAntigenChecked: boolean;
  StrepChecked: boolean;
  DengueNsChecked: boolean;
  DengueIgmChecked: boolean;
  ChikungunyaIgmChecked: boolean;
  IfobChecked: boolean;
  EcgChecked: boolean;
  TcChecked: boolean;
  TgChecked: boolean;
  LdlChecked: boolean;
  HdlChecked: boolean;
  RandomChecked: boolean;
  FastingChecked: boolean;
  PostPrandialChecked: boolean;
  ThreePartsChecked: boolean;
  TyphoidChecked: boolean;
  CholeraChecked: boolean;
  HivChecked: boolean;
  HbsagChecked: boolean;
  HcvChecked: boolean;
  MaleriaChecked: boolean;
  DengueChecked: boolean;
  ChikungunyaChecked: boolean;
  LeptospiraChecked: boolean;
  PyloriChecked: boolean;
  ScrubChecked: boolean;
  ElephantiasisChecked: boolean;
  SyphilisChecked: boolean;
  LeishmaniaChecked: boolean;
  DipTestChecked: boolean;

  pricesData: { [key: string]: number } = {
    BodyCompositionChecked: 360,
    BloodPressureChecked: 817,
    TempChecked: 987,
    OXYChecked: 721,
    EcgChecked: 604,
    CkmbChecked: 199,
    TnlChecked: 562,
    DimerChecked: 304,
    proBnpChecked: 671,
    hsCRPChecked: 908,
    LipidProfileChecked: 183,
    HcgChecked: 102,
    LhChecked: 949,
    T4Checked: 276,
    fT4Checked: 532,
    TshWbChecked: 386,
    TshChecked: 744,
    CrpChecked: 156,
    PctChecked: 645,
    Hba1cChecked: 793,
    BloodGlucoseChecked: 607,
    AlbuminChecked: 99,
    invasiveChecked: 300,
    RsvAgChecked: 444,
    LegionellaChecked: 810,
    SpneumoniaChecked: 312,
    CovidAntigenChecked: 715,
    StrepChecked: 965,
    DengueNsChecked: 29,
    DengueIgmChecked: 633,
    ChikungunyaIgmChecked: 215,
    IfobChecked: 722,
    TcChecked: 568,
    TgChecked: 958,
    LdlChecked: 429,
    HdlChecked: 703,
    RandomChecked: 297,
    FastingChecked: 119,
    PostPrandialChecked: 892,
    ThreePartsChecked: 532,
    TyphoidChecked: 18,
    CholeraChecked: 607,
    HivChecked: 859,
    HbsagChecked: 625,
    HcvChecked: 462,
    MaleriaChecked: 111,
    DengueChecked: 450,
    ChikungunyaChecked: 781,
    LeptospiraChecked: 63,
    PyloriChecked: 789,
    ScrubChecked: 507,
    ElephantiasisChecked: 624,
    SyphilisChecked: 108,
    LeishmaniaChecked: 725,
    DipTestChecked: 786,
  };
  queryParams: Map<string, string>;
  patientId: any;
  technicianID: any;
  centreID: any;
  reportID: any;
  patientDetails: any;
  subtotal: number;
  discount: number = 420;
  tax: number = 360;
  total: number = 0;
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((params) => {
      this.queryParams = new Map<string, string>();
      params.keys.forEach((key) => {
        this.queryParams.set(key, params.get(key));
      });
      this.patientId = this.queryParams.get("PatientID");
      this.technicianID = this.queryParams.get("TechnicianID");
      this.reportID = this.queryParams.get("_id");
      this.centreID = this.queryParams.get("CentreID");
      this.calculateSubtotal();
      this.calculateTotal();
      this.fetchPatientDetails();
    });
  }
  fetchPatientDetails(): void {
    this.http
      .get<any>(`${environment.baseUrl}/patient/get-patient/${this.patientId}`)
      .subscribe(
        (response) => {
          this.patientDetails = response;
        },
        (error) => {
          console.log(`Error fetching patient details`, error);
        }
      );
  }
  calculateSubtotal(): void {
    this.subtotal = 0;
    for (const param of this.queryParams.keys()) {
      if (
        param !== "PatientID" &&
        param !== "TechnicianID" &&
        param !== "CentreID" &&
        param !== "_id"
      ) {
        const price = this.pricesData[param];
        this.subtotal += price;
      }
    }
  }
  calculateTotal(): void {
    this.total = this.subtotal - this.discount + this.tax;
  }

  verifyPayment(response: any): void {
    this.http
      .post<any>(
        `${environment.baseUrl}/payment/paytm/verify-payment`,
        response
      )
      .subscribe(
        (response) => {
          console.log("Payment verification response:", response);
          if (response.status === "success") {
            console.log("Payment verification successfull!");
            this.addPaymentRecord();
            this.navigate();
          } else {
            console.log("Payment verification failed!");
          }
        },
        (error) => {
          console.log("Error verifying payment:", error);
        }
      );
  }

  addPaymentRecord(): void {
    this.http
      .post<any>(`${environment.baseUrl}/payment/addPaymentRecord`, {
        PatientID: this.patientId,
        TechnicianID: this.technicianID,
        CentreID: this.centreID,
        ReportID: this.reportID,
        amount: this.total,
      })
      .subscribe(
        (response) => {
          console.log("Payment record added successfully:", response);
        },
        (error) => {
          console.log("Error adding payment record:", error);
        }
      );
  }

  initiatePayment(): void {
    this.http
      .post<any>(`${environment.baseUrl}/payment/createOrder`, {
        amount: this.total,
      })
      .subscribe(
        (response) => {
          console.log(response);
        },
        (error) => {
          console.log("Error creating payment order", error);
        }
      );
  }

  paymentHandler(response: any): void {
    if (response.paytm_id) {
      console.log("Payment successful!");
      this.verifyPayment(response);
      this.navigate();
    } else {
      console.log("Payment failed!");
    }
  }
  navigate(): void {
    this.ngZone.run(() => {
      this.router.navigate([`technician/lab-report-new`], {
        queryParams: {
          PatientID: this.patientId,
          _id: this.reportID,
        },
      });
    });
  }
}
