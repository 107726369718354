import { stringify } from "@angular/compiler/src/util";
import { languageCheck } from "src/app/translation/translate";
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, NgForm } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import * as html2canvas from "html2canvas";
import * as jsPDF from "jspdf";
import { ToastrService } from "ngx-toastr";
import swal from "sweetalert/dist/sweetalert.min.js";

import { PatientResponse } from "src/app/auth/model/patientResponse";
import { TestDataResponse } from "src/app/auth/model/testResponse";
import { ReportService } from "src/app/patient/services/report.service";
import { TestDataService } from "src/app/testing/TestServices/test-data.service";
import { PatientServiceService } from "../services/patient-service.service";
import { OrthoScopeResponse } from "src/app/auth/model/OrthoScopeResponse";
console.log(languageCheck().testDetails[24].test)
@Component({
  selector: "app-lab-report-new",
  templateUrl: "./lab-report-new.component.html",
  styleUrls: ["./lab-report-new.component.css"],
})
export class LabReportNewComponent implements OnInit {
  translation = languageCheck();
  loading: boolean;
  buttionText: string;
  http: any;
  nameFormControl: any;
  patientId: any;
  testObjId: any;
  patient: PatientResponse[];
  testData: TestDataResponse[];
  testDetails: TestDataResponse[];
  octoscope: OrthoScopeResponse[];
  valueone: null;
  reportName: any;
  form: FormGroup;
  reportData: any;
  StaticDatas: any;
  datas1223;
  dpmbutton = false;
  dateVal;
  octoscopeObjId;
  form1;

  constructor(
    private fb: FormBuilder,
    private auth: PatientServiceService,
    private activatedRoute: ActivatedRoute,
    private testService: TestDataService,
    private toast: ToastrService,
    private reportService: ReportService,
    private sanitizer: DomSanitizer
  ) {
    this.getStaticDatas();
    this.dateVal = new Date();
    this.form1 = fb.group({
      PatientID: "",
      otherMailId: new FormControl(""),
    });
  }

  StaticDataObj = [
    {
      name: "IMPORTANT INSTRUCTIONS",
      points: [
        "Test results pertain to specimen submitted",
        "All test results are dependent on the quality of sample received",
        "Investigations are only a tool to facilitate in arriving at a diagnosis and should be clinically correlated by the referring physician",
        "Report delivery may be delayed due to unforeseen conditions",
        "Certain tests may require further testing at additional costs for derivation of exact value",
        "Test results may show inter/Intra laboratory variations",
        "The courts of Delhi shall have exclusive jurisdiction in all disputes/claims concerning test(s) and/or result of test(s)",
        "Test results are not valid for medico legal purposes.",
      ],
    },
  ];

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((data) => {
      console.log("Here data is: ")
      console.log(data)
      this.testObjId = data._id;
      this.octoscopeObjId = data._octoscopeObjId;
      this.patientId = data.PatientID;
      this.form1.patchValue({
        PatientID: data.PatientID,
      });
      this.getPatientDetails();
      if (this.octoscopeObjId) {
        this.getOctoscopeData();
      }
      if (this.testObjId) {
        this.getTestData();
      }
    });
    this.form = new FormGroup({
      report: new FormControl(null),
    });
  }

  @HostListener("document:scroll")
  scrollFunction() {
    if (
      document.body.scrollTop > 500 ||
      document.documentElement.scrollTop > 500
    ) {
      this.dpmbutton = true;
    } else {
      this.dpmbutton = false;
    }
  }

  transform(value) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  print() {
    window.print();
  }

  getOctoscopeData() {
    this.reportService.getOctoscope(this.octoscopeObjId).subscribe(
      (res) => {
        this.octoscope = res.OctoscopeData;
      },
      (err) => {
        if (err.status) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error("CONNECTION_ERROR");
        }
      }
    );
  }

  getPatientDetails() {
    this.auth.getPatientDetail(this.patientId).subscribe(
      (res) => {
        this.patient = res.patients;
      },
      (err) => {
        if (err.status) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error("CONNECTION_ERROR");
        }
      }
    );
  }

  getStaticDatas() {
    this.reportService.getReportTemplate().subscribe(
      (res) => {
        console.log(res);
        this.StaticDatas = {};
        res.data.forEach((element) => {
          this.StaticDatas[element.slno] = element;
        });

        console.log(this.StaticDatas);
      },
      (err) => {
        if (err.status) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error("CONNECTION_ERROR");
        }
      }
    );
  }

  getTestData() {
    console.log("get test data inside")
    this.testService.getTestDetails(this.testObjId).subscribe(
      (res) => {
        console.log(this.testObjId)
        // console.log(res)
        this.testData = res.testResults.TestData;
        console.log("This is the tstData",this.testData['KFT'])
        this.testDetails = res.testResults;
        console.log(this.testData);
        const len = Object.keys(this.testData).length;
        console.log(len,"This is the len")
      },
      (err) => {
        if (err.status) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error("CONNECTION_ERROR");
        }
      }
    );
  }

  @ViewChild("content") content: ElementRef;
  @ViewChild("reportModal", {
    static: false,
  })
  reportModal: ElementRef;
  @ViewChild("closeSendMailModel", {
    static: false,
  })
  closeSendMailModel: ElementRef;

  downloadPDF(cb = null, formData = null) {
    window.scrollTo(0, 0);
    let DATA = document.getElementById("content");
    html2canvas(DATA)
      .then((canvas) => {
        let PDF = new jsPDF("p", "mm", "a4");
        var width = PDF.internal.pageSize.width;
        var height = PDF.internal.pageSize.height;
        let A4SheetWidth = 210;
        let A4SheetHeight = 297;
        let imgHeight = (canvas.height * A4SheetWidth) / canvas.width;
     
        let temp = A4SheetHeight * 2;
        let heightLeft = imgHeight;
        
        const imgData = canvas.toDataURL("image/jpeg", 1.0);

        // let position = 0;
        // PDF.addImage(FILEURI, "JPEG", 0, position, imgWidth, imgHeight - 17);
        // heightLeft -= pageHeight;
        PDF.addImage(imgData, "JPEG", 0, 0, width, imgHeight);
        heightLeft -= A4SheetHeight;
        let position = 0;
        while (heightLeft >= 1) {
          position = heightLeft - imgHeight;
          PDF.addPage();
          PDF.addImage(imgData, "JPEG", 0, position, width, imgHeight);
          heightLeft -= A4SheetHeight;
        }
        let date = new Date();
        this.reportName = this.patientId + date.getFullYear() + date.getDate();
        PDF.autoPrint();
        // const blob = PDF.output("bloburl");
        // console.log(blob)
        // window.open(blob);
        if (!cb) PDF.save(`${this.reportName}.pdf`);
        // converts pdf to bytes
        // let data = PDF.output();

        // converts pdf to base64 string
        const data = PDF.output("datauri");

        this.auth.addReport(data, this.patientId).subscribe(
          (res) => {
            if (!res.error) {
              if (!cb) swal(res.message, "", "success");
              else
                setTimeout(() => {
                  formData ? cb(formData) : cb();
                }, 500);
            } else {
              this.toast.error(res.message);
            }
          },
          (err) => {
            if (err.status) {
              this.toast.error(err.error.message);
            } else {
              this.toast.error("CONNECTION_ERROR");
            }
          }
        );
      })
      .catch((err) => {
        this.toast.error(err);
      });
  }

  sendWhatsappPDF(cb = null, formData = null) {
    window.scrollTo(0, 0);
    let DATA = document.getElementById("content");
    html2canvas(DATA)
      .then((canvas) => {
        let PDF = new jsPDF("p", "mm", "a4");
        var width = PDF.internal.pageSize.width;
        var height = PDF.internal.pageSize.height;
        let A4SheetWidth = 210;
        let A4SheetHeight = 297;
        let imgHeight = (canvas.height * A4SheetWidth) / canvas.width;

        let heightLeft = imgHeight;
        
        const imgData = canvas.toDataURL("image/jpeg", 1.0);

        PDF.addImage(imgData, "JPEG", 0, 0, width, imgHeight);
        heightLeft -= A4SheetHeight;
        let position = 0;
        while (heightLeft >= 1) {
          position = heightLeft - imgHeight;
          PDF.addPage();
          PDF.addImage(imgData, "JPEG", 0, position, width, imgHeight);
          heightLeft -= A4SheetHeight;
        }
        const base64String = PDF.output('datauristring');
        let date = new Date();
        this.reportName = this.patientId + date.getFullYear() + date.getDate();
       // console.log('Base64 representation of PDF:', base64String);
        this.auth.addReport(base64String, this.patientId).subscribe(
          (res) => {
            this.toast.success("Massage Sent");
          },
          (err) => {
            this.toast.error("ERROR while converting PDF");
          }
        );
      })
    }

  sendMailToPatient() {
    this.toast.info("Sending Mail Please Wait!!!");
    this.testService.sendEmail(this.patientId).subscribe(
      (res) => {
        if (!res.error) {
          this.toast.success(res.message);
        } else {
          this.toast.error(res.message);
        }
      },
      (err) => {
        if (err.status) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error("CONNECTION_ERROR");
        }
      }
    );
  }

  sendMailToOther(formValue) {
    this.form1.patchValue({
      otherMailId: "",
    });
    this.closeSendMailModel.nativeElement.click();
    this.toast.info("Sending Mail Please Wait!!!");
    this.testService.sendEmailToOther(formValue).subscribe(
      (res) => {
        if (!res) {
          this.toast.error(res.message);
        } else {
          this.toast.success(res.message);
        }
      },
      (err) => {
        if (err.status) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error("CONNECTION_ERROR");
        }
      }
    );
  }

  newPage(obj, pagelimit) {
    return Object.keys(obj).length > pagelimit;
  }
}
