import { Component, OnInit } from "@angular/core";
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from "@angular/common";
@Component({
  selector: "app-farward-backward",
  templateUrl: "./farward-backward.component.html",
  styleUrls: ["./farward-backward.component.css"],
  providers: [
    Location,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy,
    },
  ],
})
export class FarwardBackwardComponent implements OnInit {
  constructor(private location: Location) {}

  ngOnInit(): void {}

  farward() {
    this.location.back();
  }

  backward() {
    this.location.forward();
  }
}
