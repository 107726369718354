import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-help",
  templateUrl: "./help.component.html",
  styleUrls: ["./help.component.css"],
})
export class HelpComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  continueToSite() {
    const url = new URL("https://superceuticals.in/index.php");
    window.open(url.toString(), "_blank");
  }
}
