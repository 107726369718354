import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "../../auth/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ReportResponse } from "src/app/auth/model/reportResponse";

@Component({
  selector: "app-home",
  templateUrl: "./pathologist-dashboard.component.html",
  styleUrls: ["./pathologist-dashboard.component.css"],
})
export class PathologistDashboardComponent implements OnInit {
  reports;
  tabSelected = "pending";
  selectedCentreID: string;
  constructor(
    private router: Router,
    private auth: AuthService,
    private toast: ToastrService,
    private http: HttpClient
  ) {
    this.getPendingReports();
    this.getCentreList();
    // this.getReviewingReports();
  }

  ngOnInit() {
    console.log(this);
  }

  setTab(str) {
    this.tabSelected = str;
    str === "pending"
      ? this.getPendingReports()
      : str === "review"
      ? this.getReviewingReports()
      : null;
  }

  onCentreSelection() {
  if (this.tabSelected === 'pending') {
    this.getPendingReports();
  } else if (this.tabSelected === 'review') {
    this.getReviewingReports();
  }
}

  getPendingReports() {
    const centreID = this.selectedCentreID;
    console.log(centreID);
    this.http
      .get<{
        error: boolean;
        data: ReportResponse[];
      }>(`${environment.baseUrl}/pathologist/pending-reports?centreID=${centreID}`)
      .subscribe(
        (res) => {
          this.reports = res.data;
        },
        (err) => {
          if (err) {
            this.toast.error("Internal Server Error");
          }
        }
      );
  }

  getReviewingReports() {
    const centreID = this.selectedCentreID; 
    console.log(centreID);
    this.http
      .get<{
        error: boolean;
        data: ReportResponse[];
      }>(`${environment.baseUrl}/pathologist/reviewing-reports?centreID=${centreID}`)
      .subscribe(
        (res) => {
          this.reports = res.data;
        },
        (err) => {
          if (err) {
            this.toast.error("Internal Server Error");
          }
        }
      );
  }

  centres = [];
  getCentreList() {
    this.http.get<any>(`${environment.baseUrl}/centre/get-centres`).subscribe(
      (res) => {
        this.centres = res.centres;
      },
      (err) => {
        this.toast.error(err.error.message);
      }
    );
  }

  reviewReport(id: string) {
    this.router.navigate([`pathologist/review-report`], {
      queryParams: {
        testID: id,
      },
    });
  }
}
