import { Injectable } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { ConsentFormComponent } from './app/consent-form/consent-form.component';
import {AudioRecordingComponent} from './app/audio-recording/audio-recording.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  isConcentApproved=false
  isCoughNeccessary=false
  CoughResponse:any;
  private responseDataSubject = new BehaviorSubject<any>(null);
  responseData$ = this.responseDataSubject.asObservable();

  private currentUrlSubject = new BehaviorSubject<string>('');
  currentUrl$ = this.currentUrlSubject.asObservable();
  constructor(
    private matdialogref:MatDialog
  ) {}
  openDialog(){
    this.matdialogref.open(ConsentFormComponent,{
      data:{
        concent:false
      }
    })
  }
  openCoughModal(){
    this.matdialogref.open(AudioRecordingComponent,{
      data:{
        concent:false
      }
    })
  }
  closeDialog() {
    this.matdialogref.closeAll();
  }

  setDataAndUrl(dataAndUrl: { data: any; url: string }) {
    this.responseDataSubject.next(dataAndUrl.data);
    this.currentUrlSubject.next(dataAndUrl.url);
  }

  
}
