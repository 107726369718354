import { environment } from "src/environments/environment";
import { LoginResponse } from "../model/loginResponse";
import { TechnicianResponse } from "../model/technicianResponse";
import { AdminLoginResponse } from "../model/adminLoginResponse";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { PathologistResponse } from "../model/pathologistResponse";
import { productResponse } from "../model/productResponse";
import { availableStock } from "../model/availableStockResponse";
import { Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  getPatientTestDatas(patientId: any) {
    throw new Error("Method not implemented.");
  }

  constructor(private http: HttpClient, private router: Router) {}

  getTechnician() {
    return this.http.get<{
      error: boolean;
      message: string;
      technician: TechnicianResponse[];
    }>(`${environment.baseUrl}/technicians/get-tech`);
  }
  getPathologist() {
    return this.http.get<{
      error: boolean;
      message: string;
      pathologist: PathologistResponse[];
    }>(`${environment.baseUrl}/pathologist/get-path`);
  }

  //get product
  getProduct() {
    return this.http.get<{
      error: Boolean;
      message: string;
      products: productResponse[];
    }>(`${environment.baseUrl}/product/get-products`);
  }
  getStockByCentreID(centreID: string): Observable<availableStock> {
    return this.http.get<availableStock>(
      `${environment.baseUrl}/centre/get-stock/${centreID}`
    );
  }

  addTechnician(techDetails) {
    return this.http.post<{
      message: string;
      error?: boolean;
    }>(`${environment.baseUrl}/technicians/add-tech`, techDetails);
  }

  addPathologist(pathDetails) {
    return this.http.post<{
      message: string;
      error?: boolean;
    }>(`${environment.baseUrl}/pathologist/add-path`, pathDetails);
  }

  // Add product
  addProduct(productDetails) {
    return this.http.post<{
      message: string;
      error?: boolean;
      data: productResponse[];
    }>(`${environment.baseUrl}/product/add-product`, productDetails);
  }

  addStock(centreID: string, stockDetails): Observable<any> {
    return this.http.post<{
      message: string;
      error?: boolean;
      stock;
    }>(`${environment.baseUrl}/centre/add-stock/${centreID}`, stockDetails);
  }

  updatePassword(resetPassword) {
    return this.http.post<{
      message: string;
      error?: boolean;
    }>(`${environment.baseUrl}/technicians/password-tech`, resetPassword);
  }

  updateTechniciandetails(technician: TechnicianResponse) {
    return this.http.put<{
      error: boolean;
      message: string;
      _id: string;
      response: TechnicianResponse;
    }>(
      `${environment.baseUrl}/technicians/edit-tech/${technician._id}`,
      technician
    );
  }

  updateProductdetails(product: productResponse) {
    return this.http.patch<{
      error: boolean;
      message: string;
      _id: string;
      response: productResponse;
    }>(`${environment.baseUrl}/product/update-product/${product._id}`, product);
  }

  deleteTechnician(id: string) {
    return this.http.delete<{
      error: boolean;
      message: string;
    }>(`${environment.baseUrl}/technicians/delete-tech/${id}`);
  }

  deletePathologist(id: string) {
    return this.http.delete<{
      error: boolean;
      message: string;
    }>(`${environment.baseUrl}/pathologist/delete-path/${id}`);
  }

  deleteProduct(id: string) {
    return this.http.delete<{
      error: boolean;
      message: string;
    }>(`${environment.baseUrl}/product/delete-product/${id}`);
  }

  loginTechnician(loginDetails) {
    return this.http.post<LoginResponse>(
      `${environment.baseUrl}/technicians/login`,
      loginDetails
    );
  }

  loginPatient(loginDetails) {
    return this.http.post<LoginResponse>(
      `${environment.baseUrl}/patient/login-patient`,
      loginDetails
    );
  }

  registerTechnician(registerDetails) {
    return this.http.post<any>(
      `${environment.baseUrl}/technicians/register`,
      registerDetails
    );
  }

  loginPathologist(loginDetails) {
    return this.http.post<LoginResponse>(
      `${environment.baseUrl}/pathologist/login`,
      loginDetails
    );
  }

  registerPathologist(registerDetails) {
    return this.http.post<any>(
      `${environment.baseUrl}/pathologist/register`,
      registerDetails
    );
  }

  getTechnicianDetails() {
    const technicianDetails = JSON.parse(
      localStorage.getItem("technicianDetails")
    );
    if (technicianDetails) {
      return technicianDetails;
    }
  }

  getTechnicianToken() {
    const technicianDetails = JSON.parse(
      localStorage.getItem("technicianDetails")
    );
    if (technicianDetails && technicianDetails.token) {
      return technicianDetails.token;
    }
  }

  getPathologistToken() {
    const pathologistDetails = JSON.parse(
      localStorage.getItem("pathologistDetails")
    );
    if (pathologistDetails && pathologistDetails.token) {
      return pathologistDetails.token;
    }
  }

  loginAdmin(AdminLoginDetails) {
    return this.http.post<AdminLoginResponse>(
      `${environment.baseUrl}/admin/login`,
      AdminLoginDetails
    );
  }

  loginCentre(CentreLoginDetails) {
    return this.http.post<AdminLoginResponse>(
      `${environment.baseUrl}/centre/login`,
      CentreLoginDetails
    );
  }

  getAdminToken() {
    const adminDetails = JSON.parse(localStorage.getItem("adminDetails"));
    if (adminDetails && adminDetails.token) {
      return adminDetails.token;
    }
  }

  getCentreToken() {
    const centreDetails = JSON.parse(localStorage.getItem("centreDetails"));
    if (centreDetails && centreDetails.token) {
      return centreDetails.token;
    }
  }

  getPatientToken() {
    const patientDetails = JSON.parse(localStorage.getItem("patientDetails"));
    if (patientDetails && patientDetails.token) {
      return patientDetails.token;
    }
  }

  getAdminDetails() {
    const adminDetails = JSON.parse(localStorage.getItem("adminDetails"));
    if (adminDetails) {
      return adminDetails;
    }
  }

  getCentreDetails() {
    const centreDetails = JSON.parse(localStorage.getItem("centreDetails"));
    if (centreDetails) {
      return centreDetails;
    }
  }

  getPathologistDetails() {
    const pathologistDetails = JSON.parse(
      localStorage.getItem("pathologistDetails")
    );
    if (pathologistDetails) {
      return pathologistDetails;
    }
  }

  getPatientDetails() {
    const patientDetails = JSON.parse(localStorage.getItem("patientDetails"));
    if (patientDetails) {
      return patientDetails;
    }
  }

  isLoggedIn() {
    const adminDetails = this.getAdminDetails;
    return adminDetails ? true : false;
  }

  logout() {
    if (this.isAdmin()) {
      this.router.navigate(["auth/admin/login"]);
    } else if (this.isTechnician()) {
      this.router.navigate(["auth/technician/login"]);
    } else if (this.isCentre()) {
      this.router.navigate(["auth/centre/login"]);
    } else if (this.isPathologist()) {
      this.router.navigate(["auth/pathologist/login"]);
    } else if (this.isPatient()) {
      this.router.navigate(["auth/patient/login"]);
    }
    localStorage.clear();
  }

  isAdmin() {
    const adminDetails = this.getAdminDetails();
    return adminDetails && adminDetails.designation === "admin" ? true : false;
  }

  isTechnician() {
    const techDetails = this.getTechnicianDetails();
    return techDetails && techDetails.designation === "Technician"
      ? true
      : false;
  }

  isPathologist() {
    const pathDetails = this.getPathologistDetails();
    return pathDetails && pathDetails.designation === "Pathologist"
      ? true
      : false;
  }

  isCentre() {
    const centreDetails = this.getCentreDetails();
    return centreDetails && centreDetails.designation === "centre"
      ? true
      : false;
  }

  isPatient() {
    const patientDetails = this.getPatientDetails();
    return patientDetails && patientDetails.designation === "Patient"
      ? true
      : false;
  }
}
