import { environment } from "src/environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ReportStaticDeatials } from "src/app/auth/model/reportStaticDetails";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  constructor(private http: HttpClient) {}

  addReportTemplate(data, test) {
    return this.http.post(`${environment.baseUrl}/report/add-report`, {
      data: data,
      test: test,
    });
  }

  getReportTemplate() {
    return this.http.get<any>(`${environment.baseUrl}/report/reports`);
  }

  getTestDetails({
    machineId = "",
    startDate = "",
    endDate = "",
    CentreID = "",
    PatientID = ""
  }) {
    let requestURL = `${environment.baseUrl}/test/get-test-result`;

    requestURL += `?MachineID=${machineId}&startDate=${startDate}&endDate=${endDate}&CentreID=${CentreID}&PatientID=${PatientID}`;

    return this.http.get<any>(requestURL);
  }

  addReportData(reportData) {
    return this.http.post<{
      error: boolean;
      message: string;
      data: ReportStaticDeatials[];
    }>(`${environment.baseUrl}/report/add-report`, reportData);
  }

  editReport(report: ReportStaticDeatials) {
    return this.http.put<{
      error: boolean;
      message: string;
      report: ReportStaticDeatials;
      _id: string;
    }>(`${environment.baseUrl}/report/edit-report/${report._id}`, report);
  }

  deleteReport(id: string) {
    return this.http.delete<{
      error: boolean;
      message: string;
    }>(`${environment.baseUrl}/report/delete/${id}`);
  }

  addOctoscope(data) {
    return this.http.post<{
      error: boolean;
      message: string;
      Prediction:string;
      Confidence:string;
      Img:any;
      response;
    }>(`${environment.baseUrl}/octoscope/add-octoscope`, data);
  }

  ganrateReport(data){
    console.log(data);
    return this.http.post<{
      message: string;
      error?: boolean;
      TestResults;
    }>(`${environment.baseUrl}/test/add-testResult`, data);
  }

  getOctoscope(id) {
    return this.http.get<{
      error: boolean;
      message: string;
      OctoscopeData;
      tempPhoto;
    }>(`${environment.baseUrl}/octoscope/get-octoscope/${id}`);
  }

  getAllOctoscope(id) {
    return this.http.get<{
      error: boolean;
      message: string;
      OctoscopeData;
    }>(`${environment.baseUrl}/octoscope/get-all-octoscope/${id}`);
  }

  getTechnicianReports(TechnicianID) {
    return this.http.get<any>(
      `${environment.baseUrl}/pathologist/rejected-reports/${TechnicianID}`
    );
  }

  markAsSeenByTechnician(ReportID) {
    return this.http.get<any>(
      `${environment.baseUrl}/technicians/report-seen/${ReportID}`
    );
  }
}
