import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { FarwardBackwardComponent } from "../farward-backward/farward-backward.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HeaderComponent } from "./pdf-components/header/header.component";
import { FooterComponent } from "./pdf-components/footer/footer.component";
import { PageLayoutComponent } from './pdf-components/page-layout/page-layout.component';
@NgModule({
  declarations: [FarwardBackwardComponent, HeaderComponent, FooterComponent, PageLayoutComponent],

  imports: [CommonModule, FormsModule, ReactiveFormsModule],

  exports: [
    CommonModule,
    FarwardBackwardComponent,
    FormsModule,
    ReactiveFormsModule,
    HeaderComponent,
    FooterComponent,
    PageLayoutComponent
  ],
})
export class SharedModule {}
