import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import * as RecordRTC from 'recordrtc';
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { SharedService } from 'src/shared.service';


interface coughName{
  status:string,
  assessmentId:string;
  data:{
   isValidCough:boolean
 }
}

@Component({
  selector: 'app-audio-recording',
  templateUrl: './audio-recording.component.html',
  styleUrls: ['./audio-recording.component.css']
})



export class AudioRecordingComponent implements OnInit {
  recording: boolean = false;
  response:any;
  title = 'audio-record';
  record: any;
  url: any;
  error: any;
  startTime: number;
  elapsedTimer:number;
  timer:any
  myForm: FormGroup;
  assessmentSubmit:boolean=false;
  assessmentReset:boolean=false;
  PatientGender:any="";
  PatientId:string;
  PAtientAge:string;
  newAssesmentId:string;

  constructor(
    private DomSanitizer: DomSanitizer,
    private http:HttpClient,
    private formBuilder: FormBuilder,
    private ActivatedRoute:ActivatedRoute,
    private Toast:ToastrService,
    private sharedServices: SharedService,
    private router: Router,
    ) {
        this.ActivatedRoute.queryParams.subscribe((res)=>{
            console.log(res)
            this.PatientGender=res.PatientGender
            this.PatientId=res.PatientId
            this.PAtientAge=res.PatientAge

            console.log(this.PatientGender,this.PatientId,this.PAtientAge)
          }),
          (err)=>{
            console.log(err)
            this.Toast.error("Connection Error")
          }   
     }

     ngOnInit(): void {
      this.myForm = this.formBuilder.group({
        frequent_cough: [false],
        cough_at_night: [false],
        sputum: [false],
        pain_in_chest: [false],
        wheezing: [false],
        shortness_of_breath: [false],
        fever: [false],
        fatigue: [false],
        sweat_heavy_at_night: [false],
        loss_of_appetite: [false],
        weight_loss: [false],
        cough_with_sputum_and_blood: [false],
        evening_rise_in_temperature: [false],
        known_person_having_active_tb: [false],
        active_tb_past: [false],
        family_history_of_asthma: [false],
        active_asthma_past: [false],
        smoking: [false],
        exposure_to_any_kind_of_smoke: [false],
        temperature_F: [false],
        pulse_rate: [false],
        respiratory_rate: [false],
        oxygen_saturation: [false],
        blood_pressure: [false],
        frequent_cough_duration: [false],
      });
    }
    

  navigate(){
    this.router.navigate(['automation-testing/automation-testing'],{
      queryParams:{
        Coughresponse:this.response
      }
    })
  }
  
  async onSubmit() {
    // Create an object to hold the values (keys with 1 or 0)
    const symptoms = {
      cough_at_night: this.myForm.get('cough_at_night').value ? "1" : "0",
      sputum: this.myForm.get('sputum').value ? "1" : "0",
      wheezing: this.myForm.get('wheezing').value ? "1" : "0",
      frequent_cough: this.myForm.get('frequent_cough').value ? "1" : "0",
      pain_in_chest: this.myForm.get('pain_in_chest').value ? "1" : "0",
      shortness_of_breath: this.myForm.get('shortness_of_breath').value ? "1" : "0",
      fatigue: this.myForm.get('fatigue').value ? "1" : "0",

      sweat_heavy_at_night: this.myForm.get('sweat_heavy_at_night').value ? "1" : "0",
      loss_of_appetite: this.myForm.get('loss_of_appetite').value ? "1" : "0",
      weight_loss: this.myForm.get('weight_loss').value ? "1" : "0",
      cough_with_sputum_and_blood: this.myForm.get('cough_with_sputum_and_blood').value ? "1" : "0",
      evening_rise_in_temperature: this.myForm.get('evening_rise_in_temperature').value ? "1" : "0",
      known_person_having_active_tb: this.myForm.get('known_person_having_active_tb').value ? "1" : "0",
      active_tb_past: this.myForm.get('active_tb_past').value ? "1" : "0",
      family_history_of_asthma: this.myForm.get('family_history_of_asthma').value ? "1" : "0",
      active_asthma_past: this.myForm.get('active_asthma_past').value ? "1" : "0",
      smoking: this.myForm.get('smoking').value ? "1" : "0",
      exposure_to_any_kind_of_smoke: this.myForm.get('exposure_to_any_kind_of_smoke').value ? "1" : "0",
      temperature_F: this.myForm.get('temperature_F').value ? "1" : "0",
      pulse_rate: this.myForm.get('pulse_rate').value ? "1" : "0",
      respiratory_rate: this.myForm.get('respiratory_rate').value ? "1" : "0",
      oxygen_saturation: this.myForm.get('oxygen_saturation').value ? "1" : "0",
      blood_pressure: this.myForm.get('blood_pressure').value ? "1" : "0",
      frequent_cough_duration: this.myForm.get('frequent_cough_duration').value ? "1" : "0",
    };
    const bodyDatas = new FormData(); 
    // Convert the entire symptoms object to a JSON string
    const symptomsJSON = JSON.stringify(symptoms); 
    // Append the JSON string to FormData
    bodyDatas.append("symptoms", symptomsJSON);
    bodyDatas.append('coughsoundfile',this.record.blob,'audio.wav')
    const assessmentId=this.generateUniqueID()
    bodyDatas.append('assessmentId',assessmentId)
    let gender=this.PatientGender ==="Male"?"1":"0" 
    bodyDatas.append("gender",gender)
    const age=this.PAtientAge
    console.log(age)
    bodyDatas.append("age",age)
    console.log(bodyDatas)
    // this.http.post<coughName>(`${environment.SwasaaUrl}/api/diseaselikely`,bodyDatas,{
    //   headers:{
    //     accessToken:environment.SwasaaAccesstoken
    //   }
    //  }).subscribe(res=>{
    //   console.log(res)
    //   this.response=res
    //   this.newAssesmentId=this.response.assessmentId
      
    //   // this.sharedServices.setDataAndUrl({ data: res, url: this.router.url });
    //   // this.Toast.success("Successfully Tested")
      
    //   // setTimeout(()=>{
    //   //  this.sharedServices.closeDialog()
    //   // },2000) 
    // },
    // (err)=>{
    //   console.log(err)
    //   this.Toast.error("An error has been occurred please test again !.")
    // }
    
    // )
    const response = await this.http.post<coughName>(`${environment.SwasaaUrl}/api/diseaselikely`, bodyDatas, {
      headers: {
        accessToken: environment.SwasaaAccesstoken
      }
    }).toPromise();

    console.log(response.assessmentId);
    this.newAssesmentId = response.assessmentId;

    const riskscoredata=new FormData();
    riskscoredata.append("symptoms", symptomsJSON)
    riskscoredata.append('assessmentId',response.assessmentId)
     console.log(riskscoredata)
    const riskScoreResponse = await this.http.post(`${environment.SwasaaUrl}/api/riskscore_assessment`, riskscoredata, {
      headers: {
        accessToken: environment.SwasaaAccesstoken
      }
    }).toPromise();
  
    console.log(riskScoreResponse, "risk score data");
    this.response = riskScoreResponse;
  
    this.sharedServices.setDataAndUrl({ data: riskScoreResponse, url: this.router.url });
    this.Toast.success("Successfully Tested");
  
    setTimeout(() => {
      this.sharedServices.closeDialog();
    }, 2000);
    

  }


 generateUniqueID() {
    const prefix = 'AXN';
    const timestamp = new Date().getTime();
    const randomPart1 = Math.floor(Math.random() * 1000); // Random number between 0 and 999
    const randomPart2 = Math.floor(Math.random() * 1000); // Random number between 0 and 999
    return `${prefix}_${timestamp}_${randomPart1}_${randomPart2}`;
  }
  


  resetForm() {
    this.myForm.reset(); // Reset the form to its initial state
  }

  sanitize(url: string) {
    return this.DomSanitizer.bypassSecurityTrustUrl(url);
  }
  
  startRecording() {
    // this.startTime=new Date().getTime()
    // this.elapsedTimer=0

    console.log("start")
    this.recording = true;
    console.log(this.recording)
    // this.startTimerfn()
    let mediaConstraints = {
      video: false,
      audio: true
    };
    navigator.mediaDevices.getUserMedia(mediaConstraints)
      .then(
        this.successCallback.bind(this)
        )
      .catch((error) => {
        console.log(error, 'Error accessing the microphone');
      });
  }

  successCallback(stream) {
    let options = {
      mimeType: 'audio/wav' as const,
      sampleRate: 44100
    };
    
    var StereoAudioRecorder = RecordRTC.StereoAudioRecorder;
    this.record = new StereoAudioRecorder(stream, options);
    this.record.record();
    
  }
  

  stopRecording() {
    this.recording = false;
    this.record.stop(this.processRecording.bind(this));
    console.log(environment.SwasaaUrl)
  }
// here we have to call
  processRecording(blob) {
    console.log('blob', blob);
    // console.log('url', this.url);
    // You can use this.url to display or save the recorded audio
    const formData=new FormData()
    formData.append('coughsoundfile',blob,'audio.wav')
     this.http.post<coughName>(`${environment.SwasaaUrl}/api/verifycough`,formData,{
      headers:{
        accessToken:environment.SwasaaAccesstoken
      }
     })
     .subscribe(res=>{
        if(res.data.isValidCough){
          this.url = URL.createObjectURL(blob);   
        }

        else{
          alert(res.data['message'])
        }
     })
  }

  resetRecording(){
    this.url=""
    this.recording = false;
    console.log("This is reset",this.url,this.recording)
  }
  errorCallback(error) {
    this.error = 'Can not play the audio in your browser';
  }

  // Timer Functions
  // startTimerfn() {
  //   this.startTime = new Date().getTime() - this.elapsedTimer * 1000;
  //   console.log(this.startTime)
  //   this.timer = setInterval(() => {
  //     this.elapsedTimer = (new Date().getTime() - this.startTime) / 1000;
  //   }, 1000);

  // }

  // stopTimer() {
  //   clearInterval(this.timer);
  // }
 
  handelAssesment(){
   this.assessmentSubmit=true;
   this.assessmentReset=true;

   console.log(this.assessmentSubmit, this.assessmentReset)
  }
  



}




