import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { AuthService } from "./auth/services/auth.service";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { SharedService } from "src/shared.service";
import { languageCheck, setLanguage } from './translation/translate';
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  //translation -------------------------^^^^^^^^^^^^^^^^^^
translations: any;

changeLanguage(newLang: string) {
  setLanguage(newLang);
  this.translations = languageCheck();
}
//translation -------------------------^^^^^^^^^^^^^^^^^^


  title = "ty-superceuticals-client";

  constructor(
    private router: Router,
    private auth: AuthService,
    private toast: ToastrService,
    private http: HttpClient,
    private SharedService:SharedService
  ) {
    this.isLoggedin();
    this.isAdminLoggedin();
  }
  islogin = false;
  adminLogin = false;
  existingPatient = false;
  adminLoginHome = false;
  NotificationComponent = false;
  newNotifications = false;

  ngOnInit() {
    this.translations = languageCheck();    //------------------^^^^^^^^^^^^^^^^^^^

    this.existPatient();
    this.notification();
    this.checkNewNotifications();
  }


  openDialog(){
    this.SharedService.openDialog()
    console.log(this.SharedService.isConcentApproved)
  }
  

  isLoggedin() {
    this.router.events.subscribe(
      (res) => {
        if (
          this.router.url === "/auth/technician/login" ||
          this.router.url === "/auth/technician/register" ||
          this.router.url === "/" ||
          this.router.url === "/auth/pathologist/login" ||
          this.router.url === "/auth/pathologist/register" ||
          this.router.url === "/auth/admin/login" ||
          this.router.url === "/auth/centre/login" ||
          this.router.url === "/auth/patient/login" || 
          this.router.url === "/auth/patient/register"
        ) {
          this.islogin = false;
        } else {
          this.islogin = true;
        }
      },
      (err) => {
        if (err.status) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error("CONNECTION_ERROR");
        }
      }
    );
  }

  isAdminLoggedin() {
    this.router.events.subscribe(
      (res) => {
        if (
          this.router.url === "/admin/admin-dashboard" ||
          this.router.url === "/home"
        ) {
          this.adminLogin = false;
          this.adminLoginHome = false;
        } else {
          this.adminLogin = true;
          this.adminLoginHome = true;
        }
      },
      (err) => {
        if (err.status) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error("CONNECTION_ERROR");
        }
      }
    );
  }

  existPatient() {
    this.router.events.subscribe(
      (res) => {
        if (this.router.url === "/technician/add-new-patient") {
          this.adminLogin = false;
          this.existingPatient = true;
        } else {
          this.adminLogin = true;
          this.existingPatient = false;
        }
      },
      (err) => {
        if (err.status) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error("CONNECTION_ERROR");
        }
      }
    );
  }

  notification() {
    this.router.events.subscribe(
      (res) => {
        if (this.router.url === "/technician/notification") {
          this.NotificationComponent = true;
          this.newNotifications = false;
        } else {
          this.NotificationComponent = false;
        }
      },
      (err) => {
        if (err.status) {
          this.toast.error(err.error.message);
        } else {
          this.toast.error("CONNECTION_ERROR");
        }
      }
    );
  }

  checkNewNotifications() {
    if (this.auth.isTechnician()) {
      const LabTechnicianID = this.auth.getTechnicianDetails().LabTechnicianID;

      const interval = setInterval(() => {
        if (!this.auth.isTechnician()) {
          clearInterval(interval);
        }

        this.http
          .get<any>(
            `${environment.baseUrl}/technicians/notifications/${LabTechnicianID}`
          )
          .subscribe(
            (res) => {
              this.newNotifications = res.newNotification;
            },
            (err) => {
              if (err.status) {
                this.toast.error(err.error.message);
              } else {
                this.toast.error("CONNECTION_ERROR");
              }
            }
          );
      }, 20000);
    }
  }
}
