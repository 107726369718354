import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private Auth: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    const designation = route.data.designation as Array<string>;
    const technicianDetails = this.Auth.getTechnicianDetails();
    console.log(route);
    if (
      technicianDetails &&
      designation.includes(technicianDetails.designation)
    ) {
      return true;
    } else {
      this.router.navigate(["auth/technician/login"]);
      return false;
    }
  }
}
