import { Component, Input, OnInit, Output, ViewEncapsulation ,EventEmitter} from '@angular/core';

import { Location } from '@angular/common';
import { SharedService } from 'src/shared.service';
import { languageCheck } from '../translation/translate';
@Component({
  selector: 'app-consent-form',
  templateUrl: './consent-form.component.html',
  styleUrls: ['./consent-form.component.css']
})
export class ConsentFormComponent implements OnInit {
  translations = languageCheck();

  consentGiven: boolean = false;
  encapsulation: ViewEncapsulation.None
  isConcentApproved: boolean = false;
  currentUrl: string;
  @Output() acceptClick = new EventEmitter<boolean>();

  constructor(
    private isSharedService: SharedService,
    private location:Location
    ) {
      this.currentUrl = location.path();
      console.log("HELLO")
      console.log(location.path())
    }

  ngOnInit(): void {
    this.handelFn()
  }

  handleAcceptClick() {
    if (this.consentGiven) {
      this.isConcentApproved = true; // Assuming the "Accept" button sets this to true
      console.log(this.isSharedService);
      this.acceptClick.emit(this.isConcentApproved);
      this.isSharedService.isConcentApproved = this.isConcentApproved;
      console.log(this.isSharedService);
    } else {
      this.isConcentApproved = false;
      this.acceptClick.emit(this.isConcentApproved);
      console.log(this.isConcentApproved);
    }
  }
 
  handleAcceptReject(){
    if(this.currentUrl=="/auth/patient/register"){
     window.location.assign("/auth/patient/login")
    }else{
      window.location.assign("/home")
    }
  }


  handelFn() {
    this.isSharedService.isConcentApproved = false;
  }
}