import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthorizationInterceptor } from "./interceptors/authorization.interceptor";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";


import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  FormControl,
} from "@angular/forms";
import { ToastrModule } from "ngx-toastr";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { SharedModule } from "./shared/shared.module";
import { AppRoutingModule } from "./app-routing.module";
import { DataTablesModule } from "angular-datatables";
import { HelpComponent } from "./help/help.component";
import { SearchPatientsPipeInHome } from "./home/pipes/search-patients.pipe";
import { InternetInterceptor } from "./interceptors/internet.interceptor";
import { SpinnerComponent } from "./spinner/spinner.component";
import { LoadingInterceptor } from "./spinner/interceptor/loading.interceptor";
import { LabReportNewComponent } from './patient/lab-report-new/lab-report-new.component';
import { PaymentComponentComponent } from './payment-component/payment-component.component';
import { ConsentFormComponent } from './consent-form/consent-form.component';
import {MatDialogModule} from "@angular/material/dialog";
import { AudioRecordingComponent } from './audio-recording/audio-recording.component'
@NgModule({
  declarations: [
    LabReportNewComponent,
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    HelpComponent,
    SearchPatientsPipeInHome,
    SpinnerComponent,
    PaymentComponentComponent,
    ConsentFormComponent,
    AudioRecordingComponent,
  ],

  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    CommonModule,
    MatDialogModule,
    ToastrModule.forRoot({
      maxOpened: 1,
      timeOut: 6000,
      preventDuplicates: true,
    }),

    SharedModule,
    DataTablesModule,
    ReactiveFormsModule,
  ],
  providers: [
    FormBuilder,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InternetInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
