import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { PatientResponse } from "../auth/model/patientResponse";
import { PatientServiceService } from "../patient/services/patient-service.service";
import { ReportService } from "../patient/services/report.service";
import { environment } from "src/environments/environment";
import { languageCheck } from '../translation/translate';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  translations = languageCheck();

  @ViewChild("PatientsRecords")
  PatientsRecords: ElementRef;
  @ViewChild("closeAddModal", {
    static: false,
  })
  CloseAddModal: ElementRef;

  searchPatient: any;
  search = "";
  patientId;
  isPatient: boolean;
  patient: PatientResponse[];
  constructor(
    private router: Router,
    private auth: PatientServiceService,
    private toast: ToastrService,
    private octoscope: ReportService
  ) {
    // this.getAllPatientDetails();
  }

  ngOnInit() {}

  onEnter(e) {
    let patientDetails = e.target.value.trim();
    console.log(patientDetails)
    this.searchPatient = e.target.value.trim();
    let patientIdVal = patientDetails.replace(/\s/g, "");
    let patientId = patientIdVal.toUpperCase();
    if (patientId === "") {
      this.toast.info("Enter PatientID");
    } else {
      // this.getPatientDetails(space);
      this.getAllPatientDetails(patientDetails);

      e.target.value = "";
      this.PatientsRecords.nativeElement.click();
    }
  }

  getAllPatientDetails(patientDetail) {
    console.log
    this.auth.getPatient(patientDetail).subscribe(
      (res) => {
        // console.log(res.patients.length,"Check this")
        this.patient = res.patients;
        if(res.patients.length){
        this.toast.success("Results Fetched!");
        }else{
          this.toast.error("No Results Found!");
        }
      },
      (err) => {
        if (err) {
          this.toast.error("Internal Server Error");
        }
      }
    );
  }
  singlePatient: PatientResponse[];
  testDatas;

  getPatientDetails(patientId) {
    this.auth.getPatientDetail(patientId).subscribe(
      (res) => {
        this.singlePatient = res.patients;
        console.log(this.singlePatient);
        this.PatientsRecords.nativeElement.click();
      },
      (err) => {
        this.toast.error(err.error.message);
      }
    );
  }

  patientTests: [];
  patientOctoscopeTests: [];
  patientTestsLength = 0;
  patientOctoscopeTestsLength = 0;
  getPatientTestDatas(patientId) {
    this.auth.getPatientTestDatas(patientId).subscribe(
      (res) => {
        this.patientTests = res.Test;
        this.patientTestsLength = this.patientTests.length;
      },
      (err) => {
        this.toast.error(err.error.message);
      }
    );
  }

  getPatientOctoscopeDatas(patientId) {
    this.octoscope.getAllOctoscope(patientId).subscribe(
      (res) => {
        this.patientOctoscopeTests = res.OctoscopeData;
        this.patientOctoscopeTestsLength = this.patientOctoscopeTests.length;
      },
      (err) => {
        this.toast.error(err.error.message);
      }
    );
  }
  onSelectPatient(patientId) {
    this.CloseAddModal.nativeElement.click();
    this.router.navigate([`technician/existing-patient`], {
      queryParams: {
        PatientId: patientId,
      },
    });
  }
  continutToReportOctoscope(data) {
    this.CloseAddModal.nativeElement.click();
    this.router.navigate([`technician/lab-report`], {
      queryParams: {
        PatientID: data.PatientID,
        _octoscopeObjId: data._id,
      },
    });
  }
  continutToReportTest(data) {
    if (data.SignedReportURL) {
      window.open(data.SignedReportURL, "_blank");
    } else {
      const url = new URL("technician/lab-report-new", environment.clientURL);
      url.searchParams.set("PatientID", data.PatientID);
      url.searchParams.set("_id", data._id);
      window.open(url.toString(), "_blank");
    }
  }
}
