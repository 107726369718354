import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./auth/guards/auth-guards";
import { HelpComponent } from "./help/help.component";
import { HomeComponent } from "./home/home.component";
import { PathologistDashboardComponent } from "./pathologist/pathologist-dashboard/pathologist-dashboard.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/auth/technician/login",
    pathMatch: "full",
  },
  {
    path: "lib",
    component: PathologistDashboardComponent,
    data: {
      designation: ["", "pathologist"],
    },
    canActivate: [AuthGuard],
  },
  {
    path: "pathologist",
    loadChildren: () =>
      import("./pathologist/pathologist.module").then(
        (m) => m.PathologistModule
      ),
    data: {
      designation: ["", "pathologist"],
    },
  },
  {
    path: "home",
    component: HomeComponent,
    data: {
      designation: ["", "Technician"],
    },
    canActivate: [AuthGuard],
  },
  {
    path: "helpInfo",
    component: HelpComponent,
  },
  {
    path: "admin",
    loadChildren: () =>
      import("./admin/admin.module").then((m) => m.AdminModule),
    data: {
      designation: ["", "admin"],
    },
  },
  {
    path: "auth",
    loadChildren: () => import("./auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "centre",
    loadChildren: () =>
      import("./centre/centre.module").then((m) => m.CentreModule),
  },
  {
    path: "technician",
    loadChildren: () =>
      import("./patient/technician.module").then((m) => m.TechnicianModule),
  },
  {
    path: "patient",
    loadChildren: () =>
      import("./patient/patient.module").then((m) => m.PatientModule),
  },
  {
    path: "testing",
    loadChildren: () =>
      import("./testing/testing.module").then((m) => m.TestingModule),
  },
  {
    path: "404",
    component: PageNotFoundComponent,
    data: {
      designation: ["admin", "technician"],
    },
    canActivate: [AuthGuard],
  },
  {
    path: "**",
    redirectTo: "/auth/technician/login",
  },
];
@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  isLogin = false;
}
