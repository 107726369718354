// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   clientURL: "http://localhost:4200",
//   baseUrl: "http://localhost:2001",
//   lipidProfileUrl: "http://10.10.20.177:5000",
//   bodyCompositionUrl: "http://192.168.1.2:5000",
//   OXYUrl: "http://104.211.118.179/api/esp32/spo2/2A-20-62-92-DA-5F",
//   SugarUrl: "http://104.211.118.179/api/esp32/sugar/2A-20-62-92-DA-5F",
//   BloodPressureUrl: "http://104.211.118.179/api/esp32/bp/2A-20-62-92-DA-5F",
//   DengueNs1Url: "http://localhost:5000/dengueNs1Test",
//   PerkantTechBaseUrl:"https://dev-main.abhayparimitii.cloudns.asia",
//   PerkantMachineId:"862818049303771",
//   CBCUrl: "http://localhost:2001/test-one/get-latest-test",
//   Hba1cUrl: "http://api.superceuticals.in/test-two/get-latest-test",
//   machineId: "MC0002",
//   SwasaaUrl:"https://swaasa.sandbox.swaasa.ai",
//   SwasaaAccesstoken:"eyJhbGciOiJIUzUxMiIsImlhdCI6MTY5NjU1MDYzNiwiZXhwIjoxOTU1NzUwNjM2fQ.eyJsb2dpbklkIjoic2FuZGJveHRlc3RAc3dhYXNhLmFpIiwiclR5cGUiOiJhZG1pbiIsInBsYW4iOiJ2aXRhbHMscGx1cyIsImFjY2Vzc1Rva2VuIjpudWxsLCJ1c2VyRGV0YWlscyI6bnVsbCwicGFyZW50SWQiOm51bGx9.943a8ulsNbCrHPqpgZuKgxWUQkd1Z9wBYm1_OM_wHQ_hyH4bx-LRlAFA1gkjThR5gC-unw82Ok9OpMgIhnNSBw",
//   GeoAccess:"8b127dbfd6f90ec699096362421e465a",
//   UrineTestUrl:"http://api.superceuticals.in/test-three/get-latest-test"

// };
export const environment = {
  production: false,
  clientURL: "https://app.superceuticals.in",
  baseUrl: "https://api.superceuticals.in",
  // clientURL: "http://localhost:4200",
  // baseUrl: "http://localhost:2001",
  lipidProfileUrl: "https://10.10.20.177:5000",
  bodyCompositionUrl: "https://192.168.1.2:5000",
  OXYUrl: "https://104.211.118.179/api/esp32/spo2/2A-20-62-92-DA-5F",
  SugarUrl: "https://104.211.118.179/api/esp32/sugar/2A-20-62-92-DA-5F",
  // BloodPressureUrl: "http://104.211.118.179/api/esp32/bp/2A-20-62-92-DA-5F",
  // BloodPressureUrl:"http://localhost:2001/vitalsReport/start-report",
  PerkantTechBaseUrl:"https://dev-main.abhayparimitii.cloudns.asia",
  PerkantMachineId:"PCL482254117615",
  DengueNs1Url: "http://localhost:5000/dengueNs1Test",
  CBCUrl: "https://api.superceuticals.in/test-one/get-latest-test",
  Hba1cUrl: "https://api.superceuticals.in/test-two/get-latest-test",
  machineId: "MC0003",
  SwasaaUrl:"https://swaasa.sandbox.swaasa.ai",
  SwasaaAccesstoken:"eyJhbGciOiJIUzUxMiIsImlhdCI6MTcyMTcxODU2OSwiZXhwIjoxOTgwOTE4NTY5fQ.eyJ1c2VySWQiOm51bGwsImxvZ2luSWQiOiJkaXNlYXNlX3ByZWRAc3dhYXNhLmFpIiwiclR5cGUiOiJhZG1pbiIsInBsYW4iOiJ2aXRhbHMscGx1cyx0Yixjb3BkLGFzdGhtYSxzcGlyb21ldHJ5IiwiYWNjZXNzVG9rZW4iOm51bGwsInVzZXJEZXRhaWxzIjpudWxsLCJwYXJlbnRJZCI6bnVsbCwiY29uZmlndXJhdGlvbiI6e319.w8l-tbdal_61Omqw6qB5lE6lsNqr-SN3HKcHsdf-xbiF61PXiWO71MzwX411gBAFk6QhWbg3BH9Mco57jyb07A",
  UrineTestUrl:"https://api.superceuticals.in/test-three/get-latest-test",
  UrinLegacyTest:"https://api.superceuticals.in/test-three/get-latest-legacy-test"
    // CancerUrl:"http://api.superceuticals.in/"
};

