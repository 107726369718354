import { Component, OnInit } from "@angular/core";
import { AuthService } from "../auth/services/auth.service";

@Component({
  selector: "app-page-not-found",
  templateUrl: "./page-not-found.component.html",
  styleUrls: ["./page-not-found.component.css"],
})
export class PageNotFoundComponent implements OnInit {
  admin;
  technician = true;

  constructor(private auth: AuthService) {}

  ngOnInit(): void {
    this.isAdmin();
  }

  isAdmin() {
    this.admin = this.auth.isAdmin();
    if (this.admin) {
      this.technician = false;
    } else {
      this.technician = true;
    }
  }
}
